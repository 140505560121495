.SplitButton {
    display: flex;
}

.Main {
    border-end-end-radius: 0 !important;
    border-top-right-radius: 0 !important;
    &.outlined {
        border-right: 0;
    }
}

.IconButton {
    padding: var(--spacing-xSmall) !important;
    border-top-left-radius: 0 !important;
    border-end-start-radius: 0 !important;
    border-left: 0;
    &:not(.blended):not(.outlined) {
        border-left: 0.5px solid !important;
    }

}

.Tooltip {
    height: 100%;
}