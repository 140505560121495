.Root {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  padding: var(--spacing-medium) var(--spacing-xLarge);
  border-bottom: 1px solid var(--palette-border-subdued);
  gap: var(--spacing-xLarge);
  background: var(--palette-background-default);

  &.Sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.Right {
  margin-left: auto;
}