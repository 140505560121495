.DatePicker {
  all: unset;
}

.Day {
  width: 39px;
  height: 39px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 100%;
  cursor: pointer;

  .DayTitle {
    color: var(--palette-foreground-default);
  }

  &.Weekend {
    .DayTitle {
      color: var(--palette-foreground-subdued);
    }
  }
  &.Blocked {
    cursor: default;
    .DayTitle {
      color: var(--palette-foreground-ghosted);
    }
  }
  &:hover:not(.Blocked):not(.Selected) {
    background: var(--palette-surface-default);
  }
  &.Selected {
    background: var(--palette-primary-default);
    .DayTitle {
      color: white;
    }
  }
}

.Nav {
  cursor: pointer;
  position: absolute;
  top: 24px;

  &.Prev {
    left: 50px;
  }
  &.Next {
    right: 50px;
  }
}

.FirstDayOfWeek_0 {
  li:last-child,
  li:first-child {
    .WeekHeader {
      color: var(--palette-foreground-subdued);
    }
  }
}

.FirstDayOfWeek_1 {
  li:last-child,
  li:nth-last-child(2) {
    .WeekHeader {
      color: var(--palette-foreground-subdued);
    }
  }
}

.FirstDayOfWeek_2 {
  li:nth-last-child(2),
  li:nth-last-child(3) {
    .WeekHeader {
      color: var(--palette-foreground-subdued);
    }
  }
}

.FirstDayOfWeek_3 {
  li:nth-last-child(3),
  li:nth-last-child(4) {
    .WeekHeader {
      color: var(--palette-foreground-subdued);
    }
  }
}

.FirstDayOfWeek_4 {
  li:nth-last-child(4),
  li:nth-last-child(5) {
    .WeekHeader {
      color: var(--palette-foreground-subdued);
    }
  }
}

.FirstDayOfWeek_5 {
  li:nth-last-child(5),
  li:nth-last-child(6) {
    .WeekHeader {
      color: var(--palette-foreground-subdued);
    }
  }
}

.FirstDayOfWeek_6 {
  li:nth-last-child(6),
  li:nth-last-child(7) {
    .WeekHeader {
      color: var(--palette-foreground-subdued);
    }
  }
}
