.P {
  font-weight: 500;
  font-size: var(--typography-size-p);
  letter-spacing: 0.01em;
  line-height: 1.313rem;
  margin: 0;
  font-family: var(--typography-font-text);
}

.regular {
  font-weight: 400;
}
