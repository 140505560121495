$nav-width: 270px;

.Root {
  max-width: 760px;
  margin: 5rem auto;
}

.Nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $nav-width;
  background: #1B263A;
}

.Content {
  margin-left: $nav-width;
}
