.Title {
  font-family: var(--typography-font-heading);
  font-weight: 400;
  font-size: var(--typography-size-title);
  color: var(--palette-foreground-subdued);
  line-height: 1.25rem;
  letter-spacing: 0.01em;
  margin: 0;
}

.small {
  font-size: 0.6875rem;
}

.bold {
  font-weight: 500;
  color: var(--palette-foreground-default);
}

.uppercase {
  text-transform: uppercase;
  font-weight: 600;
}

