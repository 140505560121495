@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--typography-font-text);
  font-size: var(--typography-size-p);
}
