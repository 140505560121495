.Select {
  caret-color: transparent;
  cursor: pointer;

  &.Disabled {
    cursor: default;
  }
}

.Wrapper {
  min-width: 0;
  width: fit-content;
  max-width: 100%;
}

.NoWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
