.PlacedToast {
  position: fixed;
  z-index: var(--elevation-pluto);
}

.bottom-center {
  bottom: var(--toast-vertical-threshold);
  left: 50%;
  transform: translate(-50%, 0);
}

.top-center {
  top: var(--toast-vertical-threshold);
  left: 50%;
  transform: translate(-50%);
}

.top-right {
  top: var(--toast-vertical-threshold);
  right: var(--toast-horizontal-threshold);
}

.bottom-right {
  bottom: var(--toast-vertical-threshold);
  right: var(--toast-horizontal-threshold);
}

.top-left {
  top: var(--toast-vertical-threshold);
  left: var(--toast-horizontal-threshold);
}

.bottom-left {
  bottom: var(--toast-vertical-threshold);
  left: var(--toast-horizontal-threshold);
}

//animations

.top-left,
.bottom-left {
  animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  &.Leaving {
    animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}

.top-right,
.bottom-right {
  animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  &.Leaving {
    animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}

.bottom-center {
  animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  &.Leaving {
    animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}

.top-center {
  animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  &.Leaving {
    animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translate(-50%, 1000px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes slide-out-bottom {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 1000px);
    opacity: 0;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translate(-50%, -1000px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes slide-out-top {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -1000px);
    opacity: 0;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translate(1000px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slide-out-right {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(1000px, 0);
    opacity: 0;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translate(-1000px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slide-out-left {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-1000px, 0);
    opacity: 0;
  }
}
