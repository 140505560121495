.CircularLoader {
  display: inline-block;
  position: relative;

  &.tiny {
    width: 16px;
    height: 16px;

    div {
      width: inherit;
      height: inherit;
      border: 3px solid;
    }
  }

  &.small {
    width: 20px;
    height: 20px;

    div {
      width: 20px;
      height: 20px;
      border: 4px solid;
    }
  }

  &.medium {
    width: 40px;
    height: 40px;

    div {
      width: 40px;
      height: 40px;
      border: 6px solid;
    }
  }

  &.large {
    width: 60px;
    height: 60px;

    div {
      width: 60px;
      height: 60px;
      border: 8px solid;
    }
  }

  &.FillParent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.CircularLoader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 8px solid;
  border-radius: 50%;
  animation: CircularLoader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.CircularLoader div:nth-child(1) {
  animation-delay: -0.45s;
}
.CircularLoader div:nth-child(2) {
  animation-delay: -0.3s;
}
.CircularLoader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes CircularLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
