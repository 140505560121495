.Drawer {
  background: white;
  max-width: 90vw;
  overflow-y: auto;
  &.Desktop {
    border-left: 1px solid var(--palette-border-default);
  }
}

.Drawer.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: var(--elevation-venus);
}

.Drawer.Global {
  position: fixed;
}

.Drawer.layout {
  z-index: var(--elevation-venus);
}

.Backdrop {
  z-index: var(--elevation-mercury);
}

.Drawer.overlay.right {
  right: 0;
  animation: slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.Drawer.overlay.left {
  left: 0;
  animation: slide-in-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.Drawer.overlay.right.Closing {
  animation: slide-out-right 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.Drawer.layout.right {
  right: 0;
  animation: slide-in-right-layout 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.Drawer.layout.right.Closing {
  animation: slide-out-right-layout 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.Drawer.overlay.left.Closing {
  animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.Drawer.layout.left {
  animation: slide-in-left-layout 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.Drawer.layout.left.Closing {
  animation: slide-out-left-layout 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(1000px);
    opacity: 0;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-1000px);
    opacity: 0;
  }
}

@keyframes slide-in-right-layout {
  0% {
    opacity: 0;
  }
  100% {
    margin-right: 0;
    opacity: 1;
  }
}

@keyframes slide-out-right-layout {
  0% {
    margin-right: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-in-left-layout {
  0% {
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes slide-out-left-layout {
  0% {
    margin-left: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
