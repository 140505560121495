.OptionListItem {
  display: grid;
  grid-auto-flow: column;
  gap: var(--spacing-small);
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-small);
  background: none;
  border-radius: var(--rounding-small);
  position: relative;
  cursor: default;
  width: 100%;
  appearance: none;
  border: none;

  &:hover:not(.DisableHoverStyle) {
    background-color: var(--palette-background-hovered);
  }

  &.Disabled:hover {
    background: none;
  }

  &.Clickable:not(.Disabled) {
    cursor: pointer;
  }
}

.TitleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.ActionWrapper {
  display: flex;
  gap: var(--spacing-medium);
  align-items: center;
  overflow: hidden;
}

.Title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--palette-foreground-default);
  &.Disabled {
    color: var(--palette-foreground-dimmed);
  }
}

.Indicator {
  position: absolute;
  top: 0;
  left: calc(-1 * var(--spacing-small));
}

.Adornment {
  display: flex;
  justify-content: center;
  align-items: center;
  &.Start {
    margin-right: var(--spacing-small);
  }
  &.Disabled svg {
    fill: var(--palette-foreground-dimmed);
    stroke: var(--palette-foreground-dimmed);
  }
}

.Checkbox {
  margin-right: var(--spacing-large);
  flex-shrink: 0;
}

.ActionButton {
  justify-self: flex-end;
  fill: var(--palette-foreground-dimmed);
  stroke: var(--palette-foreground-dimmed);
}

.ActionButton:hover {
  fill: var(--palette-foreground-default);
  stroke: var(--palette-foreground-default);
}
