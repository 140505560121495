.DayContainer {
  padding: 0;
  cursor: pointer;
}

.Day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
}

.Selected {
  background: var(--palette-primary-default);

  .DayTitle {
    color: white;
  }
}

.SelectedSpan {
  opacity: 0.5;
  background: var(--palette-primary-default);

  .DayTitle {
    color: white;
  }
}

.Hovered {
  border-radius: 100%;
  opacity: 1.0;
  background: var(--palette-primary-default);
  .DayTitle {
    color: white;
  }
}

.Hovered.SelectedSpan {
  border-radius: 0%;
}

.HoveredSpan {
  @extend .SelectedSpan;
}

.Hovered.HoveredSpan {
  opacity: 1.0;
  background: var(--palette-primary-default);
  border-bottom-right-radius: 40%;
  border-top-right-radius: 40%;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  .DayTitle {
    color: white;
  }
}

.SelectedStart {
  background: var(--palette-primary-default);
  border-bottom-left-radius: 40%;
  border-top-left-radius: 40%;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;

  .DayTitle {
    color: white;
  }
}

.SelectedEnd {
  background: var(--palette-primary-default);
  border-bottom-right-radius: 40%;
  border-top-right-radius: 40%;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;

  .DayTitle {
    color: white;
  }
}

.SelectedStartInHoveredSpan {
  opacity: 1.0;
  background: var(--palette-primary-default);
}

.Nav {
  cursor: pointer;
  position: absolute;
  top: 24px;

  &.Prev {
    left: 50px;
  }
  &.Next {
    right: 50px;
  }
}
