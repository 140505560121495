
.TextArea {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-radius: var(--rounding-small);
  border-color: var(--palette-border-default);
  background-color: var(--palette-background-default);
  resize: none;
  padding: var(--spacing-medium) var(--spacing-large);
  font-size: var(--typography-size-p);
  line-height: 1.25rem;
  color: var(--palette-foreground-default);
  font-family: var(--typography-font-text);
  box-sizing: border-box;

  transition: border-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    border-color: var(--palette-border-hovered);
    resize: auto;
  }

  &.Active {
    border-color: var(--palette-primary-default);
  }

  &.Disabled {
    border-color: var(--palette-border-subdued);
    background-color: var(--palette-surface-dimmed);
    color: var(--palette-foreground-dimmed);
    
    &:hover {
      resize: none;
    }
  }

  &.blended {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0);

    &:hover {
      border-color: var(--palette-border-hovered);
    }
    &.Disabled:hover {
      border-color: rgba(0, 0, 0, 0);
    }
    &.Readonly:hover {
      border-color: rgba(0, 0, 0, 0);
    }

    &.Active {
      border-color: var(--palette-primary-default);
    }
  }


  &.Error {
    border-color: var(--palette-danger-default);
    background-color: var(--palette-danger-ghosted);
    color: var(--palette-danger-default);
    svg {
      fill: var(--palette-danger-default);
      stroke: var(--palette-danger-default);
    }
    &.blended {
      border-color: rgba(0, 0, 0, 0);
      &:hover {
        border-color: var(--palette-danger-hovered);
      }
    }
  }

  &.Readonly {
    resize:none;
    &:hover {
      background: var(--theme-colors-border);
      .Input {
        cursor: default;
      }
    }
  }

  &::placeholder {
    color: var(--palette-foreground-dimmed);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: var(--palette-foreground-dimmed);
  }
  
  &::-ms-input-placeholder {
    color: var(--palette-foreground-dimmed);
  }




  &:focus {
    outline: none;
  }


}

