.Caps2 {
  font-family: var(--typography-font-text);
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.01em;
  line-height: 1.313rem;
  margin: 0;
  text-transform: uppercase;
}

.regular {
  font-weight: 400;
}
