.Indicator {
  width: 6px;
  height: 6px;
  border-radius: 100%;

  &.Vertical {
    height: 100%;
    width: 3px;
    border-radius: 0 var(--rounding-medium) var(--rounding-medium) 0;
  }
}
