.Root {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--rounding-medium);
}

.Blended {
  background: transparent !important;
}

.Small {
  width: 24px;
  height: 24px;
}

.Medium {
  width: 36px;
  height: 36px;
}