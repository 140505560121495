.Wrapper {
  border-style: solid;
  border-width: 1px;
  border-radius: var(--rounding-medium);
  display: flex;
  align-items: center;
  border-color: var(--palette-border-default);
  background-color: var(--palette-background-default);

  transition: border-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover:not(.Readonly):not(.Disabled) {
    border-color: var(--palette-primary-default);
    &.DisableHover {
      border-color: none;
    }
  }

  &.Active {
    border-color: var(--palette-primary-default);
  }

  &.Disabled {
    border-color: var(--palette-border-subdued);
    background-color: var(--palette-surface-dimmed);
    svg {
      fill: var(--palette-foreground-dimmed);
      stroke: var(--palette-foreground-dimmed);
    }
  }

  &.blended {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0);

    &:hover:not(.DisableHover) {
      border-color: var(--palette-primary-default);
      &.DisableHover {
        border-color: none;
      }
    }
    &.Disabled:hover:not(.DisableHover) {
      border-color: rgba(0, 0, 0, 0);
    }
    &.Readonly:hover:not(.DisableHover) {
      border-color: rgba(0, 0, 0, 0);
    }

    &.Active {
      border-color: var(--palette-primary-default);
    }
  }

  &.Error {
    border-color: var(--palette-danger-default);
    background-color: var(--palette-danger-ghosted);
    svg {
      fill: var(--palette-danger-default);
      stroke: var(--palette-danger-default);
    }
    &.blended {
      border-color: rgba(0, 0, 0, 0);
      &:hover:not(.DisableHover) {
        border-color: var(--palette-danger-hovered);
      }
    }
  }

  &.Readonly:hover:not(.DisableHover) {
    .Input {
      cursor: default;
    }
  }
}

.Input {
  flex: 1;
  width: 100%;
  border: none;
  border-radius: var(--rounding-small);
  padding: var(--spacing-medium) 0;
  font-size: var(--typography-size-p);
  line-height: 1.25rem;
  color: var(--palette-foreground-default);
  background: none;
  font-family: var(--typography-font-text);

  &.small {
    padding: var(--spacing-small) var(--spacing-large);
  }

  &.large {
    font-size: var(--typography-size-h3);
    font-weight: 500;
  }

  &:focus {
    outline: none;
  }

  &.Error {
    color: var(--palette-danger-default);
  }

  &.Disabled {
    color: var(--palette-foreground-dimmed);
  }
}
.Input::placeholder {
  color: var(--palette-foreground-dimmed);
  opacity: 1;
}

.Input:-ms-input-placeholder {
  color: var(--palette-foreground-dimmed);
}

.Input::-ms-input-placeholder {
  color: var(--palette-foreground-dimmed);
}

.Input::-webkit-outer-spin-button,
.Input::-webkit-inner-spin-button {
  margin-left: var(--spacing-small);
}
