.Root {
  padding: 0 !important;
}

.Search {
  caret-color: var(--palette-primary-default);
}

.OptionList {
  padding: var(--spacing-small);
  border-radius: var(--rounding-small);
  max-height: 250px;
  overflow-y: auto;
}

.Description {
  padding: var(--spacing-small);
  p {
    color: var(--palette-foreground-subdued);
  }
}
.Category {
  text-align: center;
  padding: var(--spacing-small);
  display: flex;
  align-items: center;
  justify-content: center;
  .Divider {
    width: 100%;
    background: var(--palette-border-subdued);
    height: 1px;

  }
  .Divider {
    width: 100%;
    background: var(--palette-border-subdued);
    height: 1px;
  }
  p {
    position: relative;
    color: var(--palette-foreground-dimmed);
    margin: 0 var(--spacing-small);
  }

}