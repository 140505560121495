.Loader {
  display: inline-block;
  position: relative;

  &.tiny {
    width: 16px;
    height: 16px;
  }

  &.small {
    width: 20px;
    height: 20px;
  }

  &.medium {
    width: 40px;
    height: 40px;
  }

  &.large {
    width: 60px;
    height: 60px;
  }

  &.FillParent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
