.Block {
  padding: var(--spacing-xLarge) 0;
}

.Gap {
  display: grid;
  flex-direction: column;
  align-items: center;
  column-gap:  var(--spacing-medium);
  row-gap: var(--spacing-medium);
}

.centerItems {
  justify-items: center;
}

.CollapsibleBlock {
  padding: 0;

  .Header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    padding: var(--spacing-xLarge) 0;

    svg {
      transition: transform 0.25s;
    }
    gap: var(--spacing-large);
  }
  .Content {
    margin-top: var(--spacing-small);
    margin-bottom: var(--spacing-xxLarge);
    box-sizing: border-box;
    padding: 0 var(--spacing-xxLarge);
  }

  &.Closed {
    .Header {
      svg {
        transform: rotateZ(-180deg);
      }
    }
    .Content {
      overflow: hidden;
      min-height: 0;
      height: 0;
      opacity: 0;
      margin: 0;
    }
  }
}
