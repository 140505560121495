.Root {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 270px;
  background: #1B263A;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 2.5rem;
  padding-bottom: 3rem;
}

.Logo {
  margin: 3.5rem auto;
}

.Clickable {
  cursor: pointer;
}