$checkbox-size: 18px;

.Checkbox {
  display: block;
  position: relative;
  width: $checkbox-size;
  height: $checkbox-size;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.Checkbox input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;
}

.Checkbox.Readonly,
.Checkbox.Readonly input,
.Checkbox.Disabled,
.Checkbox.Disabled input {
  cursor: default;
}

.Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: $checkbox-size;
  width: $checkbox-size;
  background-color: none;
  border-radius: var(--rounding-small);
  border: 2px solid var(--palette-border-default);
  transition: 0.4s all;
  box-sizing: border-box;

  &:hover:not(.Readonly):not(.Disabled) {
    border-color: var(--palette-primary-default);
  }
  &.Disabled {
    border-color: var(--palette-border-dimmed);
  }
}

.Checkbox input:checked ~ .Checkmark {
  background-color: var(--palette-primary-default);
  border-color: rgba(0, 0, 0, 0);
  transition: 0.2s all;
}

.Checkbox input:checked ~ .Checkmark.Disabled {
  background-color: var(--palette-primary-dimmed);
  border-color: var(--palette-primary-dimmed);
}

.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 1px;
  width: 3.5px;
  height: 6.5px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.Checkbox input:checked ~ .Checkmark:after {
  display: block;
}

.Title {
  margin-left: var(--spacing-small);
  color: var(--palette-foreground-subdued);

  &.Checked {
    color: var(--palette-foreground-default);
  }

  &.Disabled {
    color: var(--palette-foreground-dimmed);
  }
}

.Wrapper {
  display: flex;
  align-items: center;
}
