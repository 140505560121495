.Root {
  margin-left: 270px;
  padding: 0 var(--spacing-xLarge);
}

.Header {
  margin: 3rem 0;
}

.Content {
  margin: 4rem auto;
  max-width: 760px;
}

.Section {
  margin-top: var(--spacing-xxLarge);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
}