.CollapsibleContent {
  transition-duration: 0.25s;
  transition-property: height, opacity, margin;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  overflow: hidden;
  height: auto;
  opacity: 1;
}

.CollapsibleContent.Closed {
  overflow: hidden;
  min-height: 0;
  height: 0;
  opacity: 0;
  &:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }
}
