
.NavSection {
  margin-top: var(--spacing-medium);

  .Content {
    margin-top: var(--spacing-medium);
    margin-left: var(--spacing-small);
  }

  .NavItem {
    display: block;
    text-decoration: none;
    cursor: pointer;
    margin: var(--spacing-small) 0;
    padding-left: var(--spacing-small);
    border-left: 2px solid rgba(0,0,0,0);

    &.Selected {
      border-color: var(--palette-primary-default);
    }
  }
}

.NavHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
  cursor: pointer;

  svg {
    fill: white;
    stroke: white;
    margin-left: var(--spacing-xSmall);
  }

  .open {
    transform: rotate(180deg);
  }
}