.ModalBackdrop {
  z-index: var(--elevation-earth);
}

.Fullscreen {
  background: none !important;
  z-index: var(--elevation-earth);
}

.Root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
  box-sizing: border-box;
  max-width: 95vw;
  &:first-child {
    margin-top: var(--spacing-xxLarge);
  }
  &:last-child {
    margin-bottom: var(--spacing-xxLarge);
  }
}

.Header {
  display: flex;
  align-items: center;
  margin: 0 var(--spacing-xxLarge);
  .Icon {
    margin-right: var(--spacing-medium);
  }

  .HeaderH4 {
    margin-right: var(--spacing-medium);
  }
}

.CloseButton {
  margin-left: auto;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
  overflow: auto;
  height: 100%;
  max-height: 50vh;
  margin: 0 var(--spacing-xxLarge);
}

.Actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-medium);
  margin: 0 var(--spacing-xxLarge);
  margin-top: var(--spacing-large);
}

.Modal {
  position: relative;
  animation: modal-in 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) both;
  display: flex;
  flex-direction: column;
  background: var(--palette-background-default);
  padding: 0 !important;
}

.FullHeight {
  position: absolute;
  top: 0;
  bottom: 0;
  .Root {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-large);
  }

  .Header {
    display: flex;
    align-items: center;
  }

  .CloseButton {
    margin-left: auto;
  }

  .Content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
    margin-right: 15%;
  }

  .Actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-medium);
    margin-top: var(--spacing-large);
  }
  max-height: initial;
  border-radius: 0;
}

.Modal.Mobile {
  .Root {
    height: 100vh;
    max-width: 100vw;
    &:first-child {
      margin: 0;
    }
    &:last-child {
      margin: 0;
    }
  }
  .Content {
    max-height: 100vh;
    padding: 0;
    margin: 0;
  }
}

.Modal.Out {
  animation: modal-out 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) both;
}

.Centered .Modal {
  width: 100%;
}

.Fullscreen .Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: initial;
  border-radius: 0;
}

@keyframes modal-in {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes modal-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  @keyframes modal-in {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes modal-out {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(1000px);
      opacity: 0;
    }
  }
}
