.ModalBackdrop {
  z-index: var(--elevation-earth);
}

.Root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: var(--spacing-xLarge);
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &.WithHeader {
    padding-top: 0;
  }

  &.WithFooter {
    padding-bottom: 0;
  }
}

.Header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: var(--spacing-xLarge) 0;
  background: var(--palette-background-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: var(--spacing-xLarge) 0;
  background: var(--palette-background-default);
  margin-top: auto;
}

.Modal {
  position: relative;
  animation: modal-in 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) both;
  display: flex;
  flex-direction: column;
  background: var(--palette-background-default);
  padding: 0 !important;
}

.Modal.Out {
  animation: modal-out 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) both;
}

@keyframes modal-in {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes modal-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  @keyframes modal-in {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes modal-out {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(1000px);
      opacity: 0;
    }
  }
}
